// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/opt/build/repo/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("/opt/build/repo/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-freelance-js": () => import("/opt/build/repo/src/pages/freelance.js" /* webpackChunkName: "component---src-pages-freelance-js" */),
  "component---src-pages-github-js": () => import("/opt/build/repo/src/pages/github.js" /* webpackChunkName: "component---src-pages-github-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-medium-js": () => import("/opt/build/repo/src/pages/medium.js" /* webpackChunkName: "component---src-pages-medium-js" */),
  "component---src-pages-page-2-js": () => import("/opt/build/repo/src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-women-who-code-js": () => import("/opt/build/repo/src/pages/women-who-code.js" /* webpackChunkName: "component---src-pages-women-who-code-js" */),
  "component---src-pages-writing-and-speaking-js": () => import("/opt/build/repo/src/pages/writing-and-speaking.js" /* webpackChunkName: "component---src-pages-writing-and-speaking-js" */)
}

